import { Power1, gsap } from 'gsap';

const logoLink = document.querySelector('.navigation__logo-link');

let allShowing = false;
let animating = false;

gsap.defaultEase = Power1.easeInOut;

logoLink.addEventListener('click', function (e) {
  e.preventDefault();

  if (!animating && !allShowing) showAll();
});

let navYOffset = 150;
if (window.innerWidth > 650) {
  navYOffset = 0;
  setTimeout(() => {
    if (!animating && !allShowing) showAll();
  }, 2000);
} else {
  setTimeout(() => {
    if (!animating && !allShowing) showAll();
  }, 0);
}

const time = gsap
  .timeline({
    onStart: () => {
      animating = true;
    },
    onComplete: () => {
      animating = false;
      allShowing = true;
    },
    onReverseComplete: () => {
      animating = false;
      allShowing = false;
    },
  })
  .to('.navigation__home-logo', { scale: 1, duration: 2 })
  .to('.navigation', { y: navYOffset, duration: 2 }, '-=2')
  .fromTo('.navigation__link--up', { opacity: 0 }, { opacity: 1, duration: 2, stagger: 0.2 }, '-=1')
  .fromTo('.index__text', { opacity: 0, y: 75 }, { opacity: 1, y: 0, duration: 1, stagger: 0.5 }, '-=2')
  .fromTo('.index__bottom-img', { opacity: 0 }, { opacity: 1, duration: 2 }, '-=3')
  .pause();

function showAll() {
  time.play();
}
